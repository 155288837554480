/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-09-27 19:38:24
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:44:32
 */
/**
 * @desc
 */
const debuggerUrl = (isDebugger = false) => {
  if (isDebugger) {
    return new Map([
      [
        '/liutong',
        [
          '/park-manage/smartEquipment',
          '/park-manage/iotClient',
          '/park-manage/parkPeoples'
        ]
      ]
    ])
  } else {
    return new Map()
  }
}
export default debuggerUrl()
