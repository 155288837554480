/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios'
import { Message } from 'element-ui'
import { baseUrl, env } from '../conf/env'
import website from '../conf/website'
import { Base64 } from 'js-base64'
import Token from '../store/util/token'
import Storage from '../store/util/storage'
import store from '../store'
import DEBUGGERURL from './conf'
import router from '../router/index'

const { fn_token__get, fn_token__remove } = Token
const { fn_storage__get } = Storage

axios.defaults.timeout = 6000000
// 返回其他状态吗
axios.defaults.validateStatus = function(status) {
  return +status >= 200 && +status <= 500 // 默认的
}

// HTTPrequest拦截
axios.interceptors.request.use(
  config => {
    const token = fn_token__get()
    const meta = config.meta || {}
    const parkId = fn_storage__get('parkId')
    // console.log(111);
    // console.log(parkId);
    const isToken = meta.isToken === false // 请求是否需要鉴权
    // console.log("config.url", config.url);
    const url = config.url
    // eslint-disable-next-line no-useless-escape
    if (url.indexOf(`http:\/\/`) === -1 && url.indexOf(`https:\/\/`) === -1) {
      // 在开发环境同时与多个服务端对接时 读取配置 调取不同服务
      let debuggerUrl = ''
      if (env.NODE_ENV === 'development') {
        const lastIndex = url.lastIndexOf('/')
        const wait_reg_url = url.slice(0, lastIndex)
        for (const [key, urls] of DEBUGGERURL.entries()) {
          if (urls.includes(wait_reg_url)) {
            debuggerUrl = key
            break
          }
        }
      }
      // console.log("baseUrl", baseUrl);
      config.url = baseUrl + debuggerUrl + config.url
    }

    if (token && !isToken) {
      config.headers['Nest-Auth'] = 'bearer ' + token // 让每个请求携带自定义token
    }
    config.headers['Authorization'] = `Basic ${Base64.encode(
      `${website.client_id}:${website.client_secret}`
    )}`

    config.headers['Cache-Control'] = 'no-cache'

    if (
      ~url.indexOf('/screenV2') ||
      ~url.indexOf('/screen01') ||
      ~url.indexOf('/screen00')
    ) {
      if (!parkId) {
        store.dispatch('fn_a_user__handle_parkInfo')
        return
      }

      if (config.data || config.params) {
        if (config.data) {
          config.data.parkId = parkId
        } else if (config.params) {
          config.params.parkId = parkId
        }
      } else {
        config.params = { parkId: parkId }
      }
    }
    // console.log('config', config)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// HTTPresponse拦截
axios.interceptors.response.use(
  response => {
    const arryObj = {
      unauthorized: '用户名或密码错误',
      invalid_grant: '用户名或密码错误',
      access_denied: '未获得用户的角色信息'
    }
    const status = +response.data.code || +response.status || 200
    let message =
      response.data.msg || response.data.message || '系统错误，联系管理员'
    if (process.env.VUE_APP_APP_ENV !== 'mock') {
      // 如果是401则跳转到登录页面
      if (status === 401 || response.status === 401) {
        if (response.status === 401) {
          const msg = arryObj[response.data.error]
          Message({
            message: msg || '登入超时，请联系管理员',
            type: 'error'
          })
        }
        const token = fn_token__get()
        if (token) {
          store.dispatch('fn_a_user__handle_logout')
        }
        return Promise.reject(
          new Error(
            (response.error && response.error.error_description) || 'error'
          )
        )
      } else if (status === 500 || response.status === 500) {
        if (response.status === 500) {
          message =
            response.data.msg ||
            response.data.message ||
            '系统错误，联系管理员 code:' + response.status
          // 2021/4/2 后台操作需要提示出具体的错误信息 故放出来了
          // message = '系统错误，联系管理员';
        }
        Message({
          message: message,
          type: 'error'
        })
        return Promise.reject(new Error(message))
      } else if (status === 404 || response.status === 404) {
        if (response.status === 404) {
          message =
            response.data.msg ||
            response.data.message ||
            '系统错误，联系管理员 code:' + response.status
          // message = '系统错误，联系管理员';
        }
        Message({
          message: message,
          type: 'error'
        })
        return Promise.reject(new Error(message))
      } else if (status === 400 || response.status === 400) {
        Message({
          message: message,
          type: 'error'
        })
        return Promise.reject(new Error(message))
      } else if (status !== 200 || response.status !== 200) {
        const msg =
          response.data.msg ||
          response.data.message ||
          arryObj[response.data.error]

        Message({
          message: msg || '请求超时异常',
          type: 'error'
        })
        return Promise.reject(new Error(message))
      }
      return response.data
    } else {
      if (status === 200) {
        return response.data
      } else {
        Message({
          message: message,
          type: 'error'
        })
        return Promise.reject({
          error: response,
          res: {
            code: 200,
            success: true
          }
        })
      }
    }
  },
  error => {
    // if (process.env.VUE_APP_APP_ENV !== 'mock') {
    //   // console.log(error);
    //   // token过期
    // //   store.dispatch('fn_a_user__handle_logout')
    //   return Promise.reject(new Error(error))
    // }
    // return Promise.reject({
    //   error: new Error(error),
    //   res: {
    //     code: 200,
    //     success: true
    //   }
    // })
  }
)

export default (config, mockData) => {
  return new Promise((resolve, reject) => {
    resolve()
  })
  return new Promise((resolve, reject) => {
    axios(config)
      .then(res => {
        if (process.env.VUE_APP_APP_ENV === 'mock' && mockData) {
          mockData = res
        }
        resolve(res)
      })
      .catch(error => {
        if (process.env.VUE_APP_APP_ENV === 'mock' && mockData) {
          error.res.data = mockData
          resolve(error.res)
        }
        return reject(error)
      })
  })
}
