/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-09-11 10:39:54
 * @LastEditors: hs
 * @LastEditTime: 2021-10-25 16:16:24
 */

// 设备初始化
export const devices = [
  {
    name: '温度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-1D',
    lMark: 'XMHJMF-1D-24F',
    position: [-325.63, 101.63, 333.43],
    type: 'Temperature',
    productKey: 'ddsf',
    deviceName: 'efstsd0',
    index: 1
  },
  {
    name: '雨量监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-1D',
    lMark: 'XMHJMF-1D-25F',
    position: [-325.63, 104.63, 333.43],
    type: 'Rainfall',
    productKey: 'ddsf',
    deviceName: 'efstsd1',
    index: 2
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-1D',
    lMark: 'XMHJMF-1D-26F',
    position: [-325.63, 108.63, 333.43],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd2',
    index: 3
  },
  {
    name: '土壤酸碱度监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-1D',
    lMark: 'XMHJMF-1D-23F',
    position: [-325.63, 98.63, 333.43],
    type: 'Ph',
    productKey: 'ddsf',
    deviceName: 'efstsd3',
    index: 4
  },
  {
    name: '消防管道压力传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-1D',
    lMark: 'XMHJMF-1D-22F',
    position: [-325.63, 95.63, 333.43],
    type: 'Press',
    productKey: 'ddsf',
    deviceName: 'efstsd4',
    index: 5
  },
  {
    name: '风速传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-1D',
    lMark: 'XMHJMF-1D-21F',
    position: [-325.63, 91.63, 333.43],
    type: 'Speed',
    productKey: 'ddsf',
    deviceName: 'efstsd5',
    index: 6
  },
  {
    name: '消防管道压力传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-2D',
    lMark: 'XMHJMF-2D-5F',
    position: [-108.29, 28.43, 364.44],
    type: 'Press',
    productKey: 'ddsf',
    deviceName: 'efstsd6',
    index: 7
  },
  {
    name: '土壤酸碱度监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-2D',
    lMark: 'XMHJMF-2D-6F',
    position: [-108.29, 32.43, 364.44],
    type: 'Ph',
    productKey: 'ddsf',
    deviceName: 'efstsd7',
    index: 8
  },
  {
    name: '风速传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-2D',
    lMark: 'XMHJMF-2D-7F',
    position: [-110.29, 35.43, 364.44],
    type: 'Speed',
    productKey: 'ddsf',
    deviceName: 'efstsd8',
    index: 9
  },
  {
    name: '液位传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-2D',
    lMark: 'XMHJMF-2D-8F',
    position: [-110.29, 39.43, 364.44],
    type: 'Liquid',
    productKey: 'ddsf',
    deviceName: 'efstsd9',
    index: 10
  },
  {
    name: '湿度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-2D',
    lMark: 'XMHJMF-2D-9F',
    position: [-110.29, 43.43, 364.44],
    type: 'Humidity',
    productKey: 'ddsf',
    deviceName: 'efstsd10',
    index: 11
  },
  {
    name: '土壤酸碱度监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-2D',
    lMark: 'XMHJMF-2D-10F',
    position: [-110.29, 47.43, 364.44],
    type: 'Ph',
    productKey: 'ddsf',
    deviceName: 'efstsd11',
    index: 12
  },
  {
    name: '土壤酸碱度监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-3D',
    lMark: 'XMHJMF-3D-10F',
    position: [11.83, 50.36, 290.81],
    type: 'Ph',
    productKey: 'ddsf',
    deviceName: 'efstsd12',
    index: 13
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-3D',
    lMark: 'XMHJMF-3D-9F',
    position: [11.83, 47.36, 290.81],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd13',
    index: 14
  },
  {
    name: '液位传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-3D',
    lMark: 'XMHJMF-3D-8F',
    position: [11.83, 44.36, 290.81],
    type: 'Liquid',
    productKey: 'ddsf',
    deviceName: 'efstsd14',
    index: 15
  },
  {
    name: '雨量监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-3D',
    lMark: 'XMHJMF-3D-7F',
    position: [11.83, 41.36, 290.81],
    type: 'Rainfall',
    productKey: 'ddsf',
    deviceName: 'efstsd15',
    index: 16
  },
  {
    name: '土壤酸碱度监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-3D',
    lMark: 'XMHJMF-3D-6F',
    position: [11.83, 37.36, 290.81],
    type: 'Ph',
    productKey: 'ddsf',
    deviceName: 'efstsd16',
    index: 17
  },
  {
    name: '湿度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-3D',
    lMark: 'XMHJMF-3D-5F',
    position: [11.83, 34.36, 290.81],
    type: 'Humidity',
    productKey: 'ddsf',
    deviceName: 'efstsd17',
    index: 18
  },
  {
    name: '湿度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-4D',
    lMark: 'XMHJMF-4D-12F',
    position: [133.146, 61.66, 57.61],
    type: 'Humidity',
    productKey: 'ddsf',
    deviceName: 'efstsd18',
    index: 19
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-4D',
    lMark: 'XMHJMF-4D-13F',
    position: [133.15, 64.67, 58.67],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd19',
    index: 20
  },
  {
    name: '温度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-10D',
    lMark: 'XMHJMF-4D-14F',
    position: [132.15, 67.8, 59.67],
    type: 'Temperature',
    productKey: 'ddsf',
    deviceName: 'efstsd20',
    index: 21
  },

  {
    name: '液位传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-4D',
    lMark: 'XMHJMF-4D-15F',
    position: [132.65, 71.3, 60.17],
    type: 'Liquid',
    productKey: 'ddsf',
    deviceName: 'efstsd22',
    index: 22
  },
  {
    name: '温度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-4D',
    lMark: 'XMHJMF-4D-16F',
    position: [133.15, 74.8, 60.67],
    type: 'Temperature',
    productKey: 'ddsf',
    deviceName: 'efstsd20',
    index: 23
  },
  {
    name: '雨量监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-4D',
    lMark: 'XMHJMF-4D-17F',
    position: [133.65, 78.3, 61.17],
    type: 'Rainfall',
    productKey: 'ddsf',
    deviceName: 'efstsd23',
    index: 24
  },
  {
    name: '湿度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-5D',
    lMark: 'XMHJMF-5D-10F',
    position: [148.72, 48.06, 96.42],
    type: 'Humidity',
    productKey: 'ddsf',
    deviceName: 'efstsd24',
    index: 25
  },
  {
    name: '消防管道压力传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-5D',
    lMark: 'XMHJMF-5D-11F',
    position: [148.62, 51.07, 96.42],
    type: 'Press',
    productKey: 'ddsf',
    deviceName: 'efstsd25',
    index: 26
  },
  {
    name: '液位传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-5D',
    lMark: 'XMHJMF-5D-12F',
    position: [147.9, 54.6, 96],
    type: 'Liquid',
    productKey: 'ddsf',
    deviceName: 'efstsd26',
    index: 27
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-5D',
    lMark: 'XMHJMF-5D-13F',
    position: [148.4, 58.1, 96.5],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd27',
    index: 28
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-5D',
    lMark: 'XMHJMF-5D-14F',
    position: [148.9, 61.6, 97],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd27',
    index: 28
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-5D',
    lMark: 'XMHJMF-5D-15F',
    position: [149.4, 65.1, 96],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd29',
    index: 30
  },
  {
    name: '湿度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-6D',
    lMark: 'XMHJMF-6D-13F',
    position: [118.86, 62.08, -233.83],
    type: 'Humidity',
    productKey: 'ddsf',
    deviceName: 'efstsd30',
    index: 31
  },
  {
    name: '雨量监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-6D',
    lMark: 'XMHJMF-6D-14F',
    position: [118.86, 65.08, -233.83],
    type: 'Rainfall',
    productKey: 'ddsf',
    deviceName: 'efstsd31',
    index: 32
  },
  {
    name: '温度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-6D',
    lMark: 'XMHJMF-6D-15F',
    position: [119.86, 69.08, -233.83],
    type: 'Temperature',
    productKey: 'ddsf',
    deviceName: 'efstsd32',
    index: 33
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-6D',
    lMark: 'XMHJMF-6D-16F',
    position: [119.86, 73.08, -233.83],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd33',
    index: 34
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-6D',
    lMark: 'XMHJMF-6D-17F',
    position: [119.86, 76.08, -233.83],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd34',
    index: 35
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-6D',
    lMark: 'XMHJMF-6D-18F',
    position: [123.86, 79.08, -233.83],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd34',
    index: 36
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-7D',
    lMark: 'XMHJMF-7D-7F',
    position: [-47.72, 36.44, -56.96],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd36',
    index: 37
  },
  {
    name: '雨量监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-7D',
    lMark: 'XMHJMF-7D-8F',
    position: [-48, 39.5, -57],
    type: 'Rainfall',
    productKey: 'ddsf',
    deviceName: 'efstsd37',
    index: 38
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-7D',
    lMark: 'XMHJMF-7D-9F',
    position: [-47.8, 44.6, -57.2],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd38',
    index: 39
  },
  {
    name: '雨量监测器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-7D',
    lMark: 'XMHJMF-7D-10F',
    position: [-47.3, 48.1, -56.7],
    type: 'Rainfall',
    productKey: 'ddsf',
    deviceName: 'efstsd39',
    index: 40
  },
  {
    name: '液位传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-7D',
    lMark: 'XMHJMF-7D-11F',
    position: [-46.8, 51.6, -56.2],
    type: 'Liquid',
    productKey: 'ddsf',
    deviceName: 'efstsd40',
    index: 41
  },
  {
    name: '湿度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-7D',
    lMark: 'XMHJMF-7D-12F',
    position: [-46.3, 55.1, -55.7],
    type: 'Humidity',
    productKey: 'ddsf',
    deviceName: 'efstsd41',
    index: 42
  },
  {
    name: '温度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-8D',
    lMark: 'XMHJMF-8D-11F',
    position: [-166.89, 53.09, 124.03],
    type: 'Temperature',
    productKey: 'ddsf',
    deviceName: 'efstsd42',
    index: 43
  },
  {
    name: '液位传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-8D',
    lMark: 'XMHJMF-8D-12F',
    position: [-167.8, 56.09, 124.01],
    type: 'Liquid',
    productKey: 'ddsf',
    deviceName: 'efstsd43',
    index: 44
  },
  {
    name: '温度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-8D',
    lMark: 'XMHJMF-8D-13F',
    position: [-168.0, 59.9, 124.0],
    type: 'Temperature',
    productKey: 'ddsf',
    deviceName: 'efstsd44',
    index: 45
  },
  {
    name: '温度传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-8D',
    lMark: 'XMHJMF-8D-14F',
    position: [-167.5, 63.5, 124.5],
    type: 'Temperature',
    productKey: 'ddsf',
    deviceName: 'efstsd45',
    index: 46
  },
  {
    name: '风速传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-8D',
    lMark: 'XMHJMF-8D-15F',
    position: [-167.0, 67.0, 125.0],
    type: 'Speed',
    productKey: 'ddsf',
    deviceName: 'efstsd46',
    index: 47
  },
  {
    name: ' 风向传感器',
    sMark: 'XMHJMF-M',
    bMark: 'XMHJMF-10D',
    lMark: ' ',
    position: [-166.5, 70.5, 125.5],
    type: 'Direction',
    productKey: 'ddsf',
    deviceName: 'efstsd47',
    index: 48
  },
  {
    name: ' 摄像头1',
    sMark: 'XMHJMF-M',
    bMark: '',
    lMark: ' ',
    position: [-157.27890118563082, 114.07192644673592, 124.54739605555969],
    type: 'CAMERA',
    productKey: 'ddsf',
    deviceName: 'efstsd49',
    index: 49
  },
  {
    name: ' 摄像头2',
    sMark: 'XMHJMF-M',
    bMark: '',
    lMark: ' ',
    position: [-47.40516455214141, 113.77254452227064, -40.47195059575857],
    type: 'CAMERA',
    productKey: 'ddsf',
    deviceName: 'efstsd50',
    index: 50
  },
  {
    name: ' 摄像头3',
    sMark: 'XMHJMF-M',
    bMark: '',
    lMark: ' ',
    position: [-323.4219491653378, 153.71953012757695, 328.9618748034049],
    type: 'CAMERA',
    productKey: 'ddsf',
    deviceName: 'efstsd50',
    index: 50
  },
  {
    name: ' 摄像头4',
    sMark: 'XMHJMF-M',
    bMark: '',
    lMark: ' ',
    position: [-117.45994515020541, 117.01670503957538, 370.12481610746073],
    type: 'CAMERA',
    productKey: 'ddsf',
    deviceName: 'efstsd50',
    index: 50
  },
  {
    name: ' 摄像头5',
    sMark: 'XMHJMF-M',
    bMark: '',
    lMark: ' ',
    position: [14.869132220145271, 88.17838047373763, 288.1271657046102],
    type: 'CAMERA',
    productKey: 'ddsf',
    deviceName: 'efstsd50',
    index: 50
  }
]

export const floor = [
  {
    id: 1,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-1F'
  },
  {
    id: 2,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-2F'
  },
  {
    id: 3,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-3F'
  },
  {
    id: 4,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-4F'
  },
  {
    id: 5,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-5F'
  },
  {
    id: 6,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-6F'
  },
  {
    id: 7,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-7F'
  },
  {
    id: 8,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-8F'
  },
  {
    id: 9,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-9F'
  },
  {
    id: 10,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-10F'
  },
  {
    id: 11,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-11F'
  },
  {
    id: 12,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-12F'
  },
  {
    id: 13,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-13F'
  },
  {
    id: 14,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-14F'
  },
  {
    id: 15,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-15F'
  },
  {
    id: 16,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-16F'
  },
  {
    id: 17,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-17F'
  },
  {
    id: 18,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-18F'
  },
  {
    id: 19,
    sMark: 'XMHJMF-M',
    // bMark: "XMHJMF-1D",
    mark: '-19F'
  }
]

// 添加设备点位
export const point = [
  {
    id: '1',
    label: '室外区域',
    name: 'XMHJMF-M',
    level: 1,
    children: []
  },
  {
    id: '2',
    label: '楼栋',
    name: 'XMHJMF-M',
    level: 1,
    children: [
      {
        id: '11',
        label: '1栋',
        name: 'XMHJMF-1D',
        level: 2,
        children: [
          {
            id: 102,
            label: '2F',
            name: 'XMHJMF-1D-2F',
            level: 3,
            children: []
          },
          {
            id: 103,
            label: '3F',
            name: 'XMHJMF-1D-3F',
            level: 3,
            children: []
          },
          {
            id: 104,
            label: '4F',
            name: 'XMHJMF-1D-4F',
            level: 3,
            children: []
          },
          {
            id: 105,
            label: '5F',
            name: 'XMHJMF-1D-5F',
            level: 3,
            children: []
          },
          {
            id: 106,
            label: '6F',
            name: 'XMHJMF-1D-6F',
            level: 3,
            children: []
          },
          {
            id: 107,
            label: '7F',
            name: 'XMHJMF-1D-7F',
            level: 3,
            children: []
          },
          {
            id: 108,
            label: '8F',
            name: 'XMHJMF-1D-8F',
            level: 3,
            children: []
          },
          {
            id: 109,
            label: '9F',
            name: 'XMHJMF-1D-9F',
            level: 3,
            children: []
          },
          {
            id: 110,
            label: '10F',
            name: 'XMHJMF-1D-10F',
            level: 3,
            children: []
          },
          {
            id: 111,
            label: '11F',
            name: 'XMHJMF-1D-11F',
            level: 3,
            children: []
          },
          {
            id: 112,
            label: '12F',
            name: 'XMHJMF-1D-12F',
            level: 3,
            children: []
          },
          {
            id: 113,
            label: '13F',
            name: 'XMHJMF-1D-13F',
            level: 3,
            children: []
          },
          {
            id: 114,
            label: '14F',
            name: 'XMHJMF-1D-14F',
            level: 3,
            children: []
          },
          {
            id: 115,
            label: '15F',
            name: 'XMHJMF-1D-15F',
            level: 3,
            children: []
          },
          {
            id: 116,
            label: '16F',
            name: 'XMHJMF-1D-16F',
            level: 3,
            children: []
          },
          {
            id: 117,
            label: '17F',
            name: 'XMHJMF-1D-17F',
            level: 3,
            children: []
          },
          {
            id: 118,
            label: '18F',
            name: 'XMHJMF-1D-18F',
            level: 3,
            children: []
          },
          {
            id: 119,
            label: '19F',
            name: 'XMHJMF-1D-19F',
            level: 3,
            children: []
          },
          {
            id: 120,
            label: '20F',
            name: 'XMHJMF-1D-20F',
            level: 3,
            children: []
          },
          {
            id: 121,
            label: '21F',
            name: 'XMHJMF-1D-21F',
            level: 3,
            children: []
          },
          {
            id: 122,
            label: '22F',
            name: 'XMHJMF-1D-22F',
            level: 3,
            children: []
          },
          {
            id: 123,
            label: '23F',
            name: 'XMHJMF-1D-23F',
            level: 3,
            children: []
          },
          {
            id: 124,
            label: '24F',
            name: 'XMHJMF-1D-24F',
            level: 3,
            children: []
          },
          {
            id: 125,
            label: '25F',
            name: 'XMHJMF-1D-25F',
            level: 3,
            children: []
          },
          {
            id: 126,
            label: '26F',
            name: 'XMHJMF-1D-26F',
            level: 3,
            children: []
          },
          {
            id: 127,
            label: '27F',
            name: 'XMHJMF-1D-27F',
            level: 3,
            children: []
          },
          {
            id: 128,
            label: '28F',
            name: 'XMHJMF-1D-28F',
            level: 3,
            children: []
          },
          {
            id: 129,
            label: '29F',
            name: 'XMHJMF-1D-29F',
            level: 3,
            children: []
          },
          {
            id: 130,
            label: '30F',
            name: 'XMHJMF-1D-30F',
            level: 3,
            children: []
          },
          {
            id: 131,
            label: '31F',
            name: 'XMHJMF-1D-31F',
            level: 3,
            children: []
          },
          {
            id: 132,
            label: '32F',
            name: 'XMHJMF-1D-32F',
            level: 3,
            children: []
          },
          {
            id: 133,
            label: '33F',
            name: 'XMHJMF-1D-33F',
            level: 3,
            children: []
          },
          {
            id: 134,
            label: '34F',
            name: 'XMHJMF-1D-34F',
            level: 3,
            children: []
          },
          {
            id: 135,
            label: '35F',
            name: 'XMHJMF-1D-35F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '12',
        label: '2栋',
        name: 'XMHJMF-2D',
        level: 2,
        children: [
          {
            id: 202,
            label: '2F',
            name: 'XMHJMF-2D-2F',
            level: 3,
            children: []
          },
          {
            id: 203,
            label: '3F',
            name: 'XMHJMF-2D-3F',
            level: 3,
            children: []
          },
          {
            id: 204,
            label: '4F',
            name: 'XMHJMF-2D-4F',
            level: 3,
            children: []
          },
          {
            id: 205,
            label: '5F',
            name: 'XMHJMF-2D-5F',
            level: 3,
            children: []
          },
          {
            id: 206,
            label: '6F',
            name: 'XMHJMF-2D-6F',
            level: 3,
            children: []
          },
          {
            id: 207,
            label: '7F',
            name: 'XMHJMF-2D-7F',
            level: 3,
            children: []
          },
          {
            id: 208,
            label: '8F',
            name: 'XMHJMF-2D-8F',
            level: 3,
            children: []
          },
          {
            id: 209,
            label: '9F',
            name: 'XMHJMF-2D-9F',
            level: 3,
            children: []
          },
          {
            id: 210,
            label: '10F',
            name: 'XMHJMF-2D-10F',
            level: 3,
            children: []
          },
          {
            id: 211,
            label: '11F',
            name: 'XMHJMF-2D-11F',
            level: 3,
            children: []
          },
          {
            id: 212,
            label: '12F',
            name: 'XMHJMF-2D-12F',
            level: 3,
            children: []
          },
          {
            id: 213,
            label: '13F',
            name: 'XMHJMF-2D-13F',
            level: 3,
            children: []
          },
          {
            id: 214,
            label: '14F',
            name: 'XMHJMF-2D-14F',
            level: 3,
            children: []
          },
          {
            id: 215,
            label: '15F',
            name: 'XMHJMF-2D-15F',
            level: 3,
            children: []
          },
          {
            id: 216,
            label: '16F',
            name: 'XMHJMF-2D-16F',
            level: 3,
            children: []
          },
          {
            id: 217,
            label: '17F',
            name: 'XMHJMF-2D-17F',
            level: 3,
            children: []
          },
          {
            id: 218,
            label: '18F',
            name: 'XMHJMF-2D-18F',
            level: 3,
            children: []
          },
          {
            id: 219,
            label: '19F',
            name: 'XMHJMF-2D-19F',
            level: 3,
            children: []
          },
          {
            id: 220,
            label: '20F',
            name: 'XMHJMF-2D-20F',
            level: 3,
            children: []
          },
          {
            id: 221,
            label: '21F',
            name: 'XMHJMF-2D-21F',
            level: 3,
            children: []
          },
          {
            id: 222,
            label: '22F',
            name: 'XMHJMF-2D-22F',
            level: 3,
            children: []
          },
          {
            id: 223,
            label: '23F',
            name: 'XMHJMF-2D-23F',
            level: 3,
            children: []
          },
          {
            id: 224,
            label: '24F',
            name: 'XMHJMF-2D-24F',
            level: 3,
            children: []
          },
          {
            id: 225,
            label: '25F',
            name: 'XMHJMF-2D-25F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '13',
        label: '3栋',
        name: 'XMHJMF-3D',
        level: 2,
        children: [
          {
            id: 301,
            label: '1F',
            name: 'XMHJMF-3D-1F',
            level: 3,
            children: []
          },
          {
            id: 302,
            label: '2F',
            name: 'XMHJMF-3D-2F',
            level: 3,
            children: []
          },
          {
            id: 303,
            label: '3F',
            name: 'XMHJMF-3D-3F',
            level: 3,
            children: []
          },
          {
            id: 304,
            label: '4F',
            name: 'XMHJMF-3D-4F',
            level: 3,
            children: []
          },
          {
            id: 305,
            label: '5F',
            name: 'XMHJMF-3D-5F',
            level: 3,
            children: []
          },
          {
            id: 306,
            label: '6F',
            name: 'XMHJMF-3D-6F',
            level: 3,
            children: []
          },
          {
            id: 307,
            label: '7F',
            name: 'XMHJMF-3D-7F',
            level: 3,
            children: []
          },
          {
            id: 308,
            label: '8F',
            name: 'XMHJMF-3D-8F',
            level: 3,
            children: []
          },
          {
            id: 309,
            label: '9F',
            name: 'XMHJMF-3D-9F',
            level: 3,
            children: []
          },
          {
            id: 310,
            label: '10F',
            name: 'XMHJMF-3D-10F',
            level: 3,
            children: []
          },
          {
            id: 311,
            label: '11F',
            name: 'XMHJMF-3D-11F',
            level: 3,
            children: []
          },
          {
            id: 312,
            label: '12F',
            name: 'XMHJMF-3D-12F',
            level: 3,
            children: []
          },
          {
            id: 313,
            label: '13F',
            name: 'XMHJMF-3D-13F',
            level: 3,
            children: []
          },
          {
            id: 314,
            label: '14F',
            name: 'XMHJMF-3D-14F',
            level: 3,
            children: []
          },
          {
            id: 315,
            label: '15F',
            name: 'XMHJMF-3D-15F',
            level: 3,
            children: []
          },
          {
            id: 316,
            label: '16F',
            name: 'XMHJMF-3D-16F',
            level: 3,
            children: []
          },
          {
            id: 317,
            label: '17F',
            name: 'XMHJMF-3D-17F',
            level: 3,
            children: []
          },
          {
            id: 318,
            label: '18F',
            name: 'XMHJMF-3D-18F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '14',
        label: '4栋',
        name: 'XMHJMF-4D',
        level: 2,
        children: [
          {
            id: 401,
            label: '1F',
            name: 'XMHJMF-4D-1F',
            level: 3,
            children: []
          },
          {
            id: 402,
            label: '2F',
            name: 'XMHJMF-4D-2F',
            level: 3,
            children: []
          },
          {
            id: 403,
            label: '3F',
            name: 'XMHJMF-4D-3F',
            level: 3,
            children: []
          },
          {
            id: 404,
            label: '4F',
            name: 'XMHJMF-4D-4F',
            level: 3,
            children: []
          },
          {
            id: 405,
            label: '5F',
            name: 'XMHJMF-4D-5F',
            level: 3,
            children: []
          },
          {
            id: 406,
            label: '6F',
            name: 'XMHJMF-4D-6F',
            level: 3,
            children: []
          },
          {
            id: 407,
            label: '7F',
            name: 'XMHJMF-4D-7F',
            level: 3,
            children: []
          },
          {
            id: 408,
            label: '8F',
            name: 'XMHJMF-4D-8F',
            level: 3,
            children: []
          },
          {
            id: 409,
            label: '9F',
            name: 'XMHJMF-4D-9F',
            level: 3,
            children: []
          },
          {
            id: 410,
            label: '10F',
            name: 'XMHJMF-4D-10F',
            level: 3,
            children: []
          },
          {
            id: 411,
            label: '11F',
            name: 'XMHJMF-4D-11F',
            level: 3,
            children: []
          },
          {
            id: 412,
            label: '12F',
            name: 'XMHJMF-4D-12F',
            level: 3,
            children: []
          },
          {
            id: 413,
            label: '13F',
            name: 'XMHJMF-4D-13F',
            level: 3,
            children: []
          },
          {
            id: 414,
            label: '14F',
            name: 'XMHJMF-4D-14F',
            level: 3,
            children: []
          },
          {
            id: 415,
            label: '15F',
            name: 'XMHJMF-4D-15F',
            level: 3,
            children: []
          },
          {
            id: 416,
            label: '16F',
            name: 'XMHJMF-4D-16F',
            level: 3,
            children: []
          },
          {
            id: 417,
            label: '17F',
            name: 'XMHJMF-4D-17F',
            level: 3,
            children: []
          },
          {
            id: 418,
            label: '18F',
            name: 'XMHJMF-4D-18F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '15',
        label: '5栋',
        name: 'XMHJMF-5D',
        level: 2,
        children: [
          {
            id: 502,
            label: '2F',
            name: 'XMHJMF-5D-2F',
            level: 3,
            children: []
          },
          {
            id: 503,
            label: '3F',
            name: 'XMHJMF-5D-3F',
            level: 3,
            children: []
          },
          {
            id: 504,
            label: '4F',
            name: 'XMHJMF-5D-4F',
            level: 3,
            children: []
          },
          {
            id: 505,
            label: '5F',
            name: 'XMHJMF-5D-5F',
            level: 3,
            children: []
          },
          {
            id: 506,
            label: '6F',
            name: 'XMHJMF-5D-6F',
            level: 3,
            children: []
          },
          {
            id: 507,
            label: '7F',
            name: 'XMHJMF-5D-7F',
            level: 3,
            children: []
          },
          {
            id: 508,
            label: '8F',
            name: 'XMHJMF-5D-8F',
            level: 3,
            children: []
          },
          {
            id: 509,
            label: '9F',
            name: 'XMHJMF-5D-9F',
            level: 3,
            children: []
          },
          {
            id: 510,
            label: '10F',
            name: 'XMHJMF-5D-10F',
            level: 3,
            children: []
          },
          {
            id: 511,
            label: '11F',
            name: 'XMHJMF-5D-11F',
            level: 3,
            children: []
          },
          {
            id: 512,
            label: '12F',
            name: 'XMHJMF-5D-12F',
            level: 3,
            children: []
          },
          {
            id: 513,
            label: '13F',
            name: 'XMHJMF-5D-13F',
            level: 3,
            children: []
          },
          {
            id: 514,
            label: '14F',
            name: 'XMHJMF-5D-14F',
            level: 3,
            children: []
          },
          {
            id: 515,
            label: '15F',
            name: 'XMHJMF-5D-15F',
            level: 3,
            children: []
          },
          {
            id: 516,
            label: '16F',
            name: 'XMHJMF-5D-16F',
            level: 3,
            children: []
          },
          {
            id: 517,
            label: '17F',
            name: 'XMHJMF-5D-17F',
            level: 3,
            children: []
          },
          {
            id: 518,
            label: '18F',
            name: 'XMHJMF-5D-18F',
            level: 3,
            children: []
          },
          {
            id: 519,
            label: '19F',
            name: 'XMHJMF-5D-19F',
            level: 3,
            children: []
          },
          {
            id: 520,
            label: '20F',
            name: 'XMHJMF-5D-20F',
            level: 3,
            children: []
          },
          {
            id: 521,
            label: '21F',
            name: 'XMHJMF-5D-21F',
            level: 3,
            children: []
          },
          {
            id: 522,
            label: '22F',
            name: 'XMHJMF-5D-22F',
            level: 3,
            children: []
          },
          {
            id: 523,
            label: '23F',
            name: 'XMHJMF-5D-23F',
            level: 3,
            children: []
          },
          {
            id: 524,
            label: '24F',
            name: 'XMHJMF-5D-24F',
            level: 3,
            children: []
          },
          {
            id: 525,
            label: '25F',
            name: 'XMHJMF-5D-25F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '16',
        label: '6栋',
        name: 'XMHJMF-6D',
        level: 2,
        children: [
          {
            id: 602,
            label: '2F',
            name: 'XMHJMF-6D-2F',
            level: 3,
            children: []
          },
          {
            id: 603,
            label: '3F',
            name: 'XMHJMF-6D-3F',
            level: 3,
            children: []
          },
          {
            id: 604,
            label: '4F',
            name: 'XMHJMF-6D-4F',
            level: 3,
            children: []
          },
          {
            id: 605,
            label: '5F',
            name: 'XMHJMF-6D-5F',
            level: 3,
            children: []
          },
          {
            id: 606,
            label: '6F',
            name: 'XMHJMF-6D-6F',
            level: 3,
            children: []
          },
          {
            id: 607,
            label: '7F',
            name: 'XMHJMF-6D-7F',
            level: 3,
            children: []
          },
          {
            id: 608,
            label: '8F',
            name: 'XMHJMF-6D-8F',
            level: 3,
            children: []
          },
          {
            id: 609,
            label: '9F',
            name: 'XMHJMF-6D-9F',
            level: 3,
            children: []
          },
          {
            id: 610,
            label: '10F',
            name: 'XMHJMF-6D-10F',
            level: 3,
            children: []
          },
          {
            id: 611,
            label: '11F',
            name: 'XMHJMF-6D-11F',
            level: 3,
            children: []
          },
          {
            id: 612,
            label: '12F',
            name: 'XMHJMF-6D-12F',
            level: 3,
            children: []
          },
          {
            id: 613,
            label: '13F',
            name: 'XMHJMF-6D-13F',
            level: 3,
            children: []
          },
          {
            id: 614,
            label: '14F',
            name: 'XMHJMF-6D-14F',
            level: 3,
            children: []
          },
          {
            id: 615,
            label: '15F',
            name: 'XMHJMF-6D-15F',
            level: 3,
            children: []
          },
          {
            id: 616,
            label: '16F',
            name: 'XMHJMF-6D-16F',
            level: 3,
            children: []
          },
          {
            id: 617,
            label: '17F',
            name: 'XMHJMF-6D-17F',
            level: 3,
            children: []
          },
          {
            id: 618,
            label: '18F',
            name: 'XMHJMF-6D-18F',
            level: 3,
            children: []
          },
          {
            id: 619,
            label: '19F',
            name: 'XMHJMF-6D-19F',
            level: 3,
            children: []
          },
          {
            id: 620,
            label: '20F',
            name: 'XMHJMF-6D-20F',
            level: 3,
            children: []
          },
          {
            id: 621,
            label: '21F',
            name: 'XMHJMF-6D-21F',
            level: 3,
            children: []
          },
          {
            id: 622,
            label: '22F',
            name: 'XMHJMF-6D-22F',
            level: 3,
            children: []
          },
          {
            id: 623,
            label: '23F',
            name: 'XMHJMF-6D-23F',
            level: 3,
            children: []
          },
          {
            id: 624,
            label: '24F',
            name: 'XMHJMF-6D-24F',
            level: 3,
            children: []
          },
          {
            id: 625,
            label: '25F',
            name: 'XMHJMF-6D-25F',
            level: 3,
            children: []
          },
          {
            id: 626,
            label: '26F',
            name: 'XMHJMF-6D-26F',
            level: 3,
            children: []
          },
          {
            id: 627,
            label: '27F',
            name: 'XMHJMF-6D-27F',
            level: 3,
            children: []
          },
          {
            id: 628,
            label: '28F',
            name: 'XMHJMF-6D-28F',
            level: 3,
            children: []
          },
          {
            id: 629,
            label: '29F',
            name: 'XMHJMF-6D-29F',
            level: 3,
            children: []
          },
          {
            id: 630,
            label: '30F',
            name: 'XMHJMF-6D-30F',
            level: 3,
            children: []
          },
          {
            id: 631,
            label: '31F',
            name: 'XMHJMF-6D-31F',
            level: 3,
            children: []
          },
          {
            id: 632,
            label: '32F',
            name: 'XMHJMF-6D-32F',
            level: 3,
            children: []
          },
          {
            id: 633,
            label: '33F',
            name: 'XMHJMF-6D-33F',
            level: 3,
            children: []
          },
          {
            id: 634,
            label: '34F',
            name: 'XMHJMF-6D-34F',
            level: 3,
            children: []
          },
          {
            id: 635,
            label: '35F',
            name: 'XMHJMF-6D-35F',
            level: 3,
            children: []
          },
          {
            id: 636,
            label: '36F',
            name: 'XMHJMF-6D-36F',
            level: 3,
            children: []
          },
          {
            id: 637,
            label: '37F',
            name: 'XMHJMF-6D-37F',
            level: 3,
            children: []
          },
          {
            id: 638,
            label: '38F',
            name: 'XMHJMF-6D-38F',
            level: 3,
            children: []
          },
          {
            id: 639,
            label: '39F',
            name: 'XMHJMF-6D-39F',
            level: 3,
            children: []
          },
          {
            id: 640,
            label: '40F',
            name: 'XMHJMF-6D-40F',
            level: 3,
            children: []
          },
          {
            id: 641,
            label: '41F',
            name: 'XMHJMF-6D-41F',
            level: 3,
            children: []
          },
          {
            id: 642,
            label: '42F',
            name: 'XMHJMF-6D-42F',
            level: 3,
            children: []
          },
          {
            id: 643,
            label: '43F',
            name: 'XMHJMF-6D-43F',
            level: 3,
            children: []
          },
          {
            id: 644,
            label: '44F',
            name: 'XMHJMF-6D-44F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '17',
        label: '7栋',
        name: 'XMHJMF-7D',
        level: 2,
        children: [
          {
            id: 702,
            label: '2F',
            name: 'XMHJMF-7D-2F',
            level: 3,
            children: []
          },
          {
            id: 703,
            label: '3F',
            name: 'XMHJMF-7D-3F',
            level: 3,
            children: []
          },
          {
            id: 704,
            label: '4F',
            name: 'XMHJMF-7D-4F',
            level: 3,
            children: []
          },
          {
            id: 705,
            label: '5F',
            name: 'XMHJMF-7D-5F',
            level: 3,
            children: []
          },
          {
            id: 706,
            label: '6F',
            name: 'XMHJMF-7D-6F',
            level: 3,
            children: []
          },
          {
            id: 707,
            label: '7F',
            name: 'XMHJMF-7D-7F',
            level: 3,
            children: []
          },
          {
            id: 708,
            label: '8F',
            name: 'XMHJMF-7D-8F',
            level: 3,
            children: []
          },
          {
            id: 709,
            label: '9F',
            name: 'XMHJMF-7D-9F',
            level: 3,
            children: []
          },
          {
            id: 710,
            label: '10F',
            name: 'XMHJMF-7D-10F',
            level: 3,
            children: []
          },
          {
            id: 711,
            label: '11F',
            name: 'XMHJMF-7D-11F',
            level: 3,
            children: []
          },
          {
            id: 712,
            label: '12F',
            name: 'XMHJMF-7D-12F',
            level: 3,
            children: []
          },
          {
            id: 713,
            label: '13F',
            name: 'XMHJMF-7D-13F',
            level: 3,
            children: []
          },
          {
            id: 714,
            label: '14F',
            name: 'XMHJMF-7D-14F',
            level: 3,
            children: []
          },
          {
            id: 715,
            label: '15F',
            name: 'XMHJMF-7D-15F',
            level: 3,
            children: []
          },
          {
            id: 716,
            label: '16F',
            name: 'XMHJMF-7D-16F',
            level: 3,
            children: []
          },
          {
            id: 717,
            label: '17F',
            name: 'XMHJMF-7D-17F',
            level: 3,
            children: []
          },
          {
            id: 718,
            label: '18F',
            name: 'XMHJMF-7D-18F',
            level: 3,
            children: []
          },
          {
            id: 719,
            label: '19F',
            name: 'XMHJMF-7D-19F',
            level: 3,
            children: []
          },
          {
            id: 720,
            label: '20F',
            name: 'XMHJMF-7D-20F',
            level: 3,
            children: []
          },
          {
            id: 721,
            label: '21F',
            name: 'XMHJMF-7D-21F',
            level: 3,
            children: []
          },
          {
            id: 722,
            label: '22F',
            name: 'XMHJMF-7D-22F',
            level: 3,
            children: []
          },
          {
            id: 723,
            label: '23F',
            name: 'XMHJMF-7D-23F',
            level: 3,
            children: []
          },
          {
            id: 724,
            label: '24F',
            name: 'XMHJMF-7D-24F',
            level: 3,
            children: []
          },
          {
            id: 725,
            label: '25F',
            name: 'XMHJMF-7D-25F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '18',
        label: '8栋',
        name: 'XMHJMF-8D',
        level: 2,
        children: [
          {
            id: 802,
            label: '2F',
            name: 'XMHJMF-8D-2F',
            level: 3,
            children: []
          },
          {
            id: 803,
            label: '3F',
            name: 'XMHJMF-8D-3F',
            level: 3,
            children: []
          },
          {
            id: 804,
            label: '4F',
            name: 'XMHJMF-8D-4F',
            level: 3,
            children: []
          },
          {
            id: 805,
            label: '5F',
            name: 'XMHJMF-8D-5F',
            level: 3,
            children: []
          },
          {
            id: 806,
            label: '6F',
            name: 'XMHJMF-8D-6F',
            level: 3,
            children: []
          },
          {
            id: 807,
            label: '7F',
            name: 'XMHJMF-8D-7F',
            level: 3,
            children: []
          },
          {
            id: 808,
            label: '8F',
            name: 'XMHJMF-8D-8F',
            level: 3,
            children: []
          },
          {
            id: 809,
            label: '9F',
            name: 'XMHJMF-8D-9F',
            level: 3,
            children: []
          },
          {
            id: 810,
            label: '10F',
            name: 'XMHJMF-8D-10F',
            level: 3,
            children: []
          },
          {
            id: 811,
            label: '11F',
            name: 'XMHJMF-8D-11F',
            level: 3,
            children: []
          },
          {
            id: 812,
            label: '12F',
            name: 'XMHJMF-8D-12F',
            level: 3,
            children: []
          },
          {
            id: 813,
            label: '13F',
            name: 'XMHJMF-8D-13F',
            level: 3,
            children: []
          },
          {
            id: 814,
            label: '14F',
            name: 'XMHJMF-8D-14F',
            level: 3,
            children: []
          },
          {
            id: 815,
            label: '15F',
            name: 'XMHJMF-8D-15F',
            level: 3,
            children: []
          },
          {
            id: 816,
            label: '16F',
            name: 'XMHJMF-8D-16F',
            level: 3,
            children: []
          },
          {
            id: 817,
            label: '17F',
            name: 'XMHJMF-8D-17F',
            level: 3,
            children: []
          },
          {
            id: 818,
            label: '18F',
            name: 'XMHJMF-8D-18F',
            level: 3,
            children: []
          },
          {
            id: 819,
            label: '19F',
            name: 'XMHJMF-8D-19F',
            level: 3,
            children: []
          },
          {
            id: 820,
            label: '20F',
            name: 'XMHJMF-8D-20F',
            level: 3,
            children: []
          },
          {
            id: 821,
            label: '21F',
            name: 'XMHJMF-8D-21F',
            level: 3,
            children: []
          },
          {
            id: 822,
            label: '22F',
            name: 'XMHJMF-8D-22F',
            level: 3,
            children: []
          },
          {
            id: 823,
            label: '23F',
            name: 'XMHJMF-8D-23F',
            level: 3,
            children: []
          },
          {
            id: 824,
            label: '24F',
            name: 'XMHJMF-8D-24F',
            level: 3,
            children: []
          },
          {
            id: 825,
            label: '25F',
            name: 'XMHJMF-8D-25F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '19',
        label: '9栋',
        name: 'XMHJMF-9D',
        level: 2,
        children: [
          {
            id: 902,
            label: '2F',
            name: 'XMHJMF-9D-2F',
            level: 3,
            children: []
          },
          {
            id: 903,
            label: '3F',
            name: 'XMHJMF-9D-3F',
            level: 3,
            children: []
          },
          {
            id: 904,
            label: '4F',
            name: 'XMHJMF-9D-4F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '20',
        label: '10栋',
        name: 'XMHJMF-10D',
        level: 2,
        children: [
          {
            id: 1002,
            label: '2F',
            name: 'XMHJMF-10D-2F',
            level: 3,
            children: []
          },
          {
            id: 1003,
            label: '3F',
            name: 'XMHJMF-10D-3F',
            level: 3,
            children: []
          },
          {
            id: 1004,
            label: '4F',
            name: 'XMHJMF-10D-4F',
            level: 3,
            children: []
          }
        ]
      },
      {
        id: '21',
        label: '11栋',
        name: 'XMHJMF-11D',
        level: 2,
        children: []
      },
      {
        id: '22',
        label: '12栋',
        name: 'XMHJMF-12D',
        level: 2,
        children: [
          {
            id: 1202,
            label: '2F',
            name: 'XMHJMF-12D-2F',
            level: 3,
            children: []
          },
          {
            id: 1203,
            label: '3F',
            name: 'XMHJMF-12D-3F',
            level: 3,
            children: []
          },
          {
            id: 1204,
            label: '4F',
            name: 'XMHJMF-12D-4F',
            level: 3,
            children: []
          }
        ]
      }
    ]
  }
]
